import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Paging from "../components/paging"
import SchoolTag from "../components/school-tag"
import { NavListItem, SchoolAreaBlock } from "../components/category-block"

export default function SchoolList({ data, pageContext }) {
  const { currentPage, numPages, areaSortList } = pageContext
  const category = 'school'
  const linkUrl = `/${category}`
  const schools = data.allSchool.edges
  return (
    <Layout slug="/school/" category={category} wrapperAddClassName="u-bgc-gray" showSchoolArea={false} showJob={false} showCharm={false} {...data.meta.nodes[0]}>
      <SchoolTag.Title>全国の歯科衛生士学校</SchoolTag.Title>
      <div className="c-school-nav">
        {NavListItem({sortList: areaSortList, countData: data.area.group, linkUrl})}
      </div>
      <div className="p-school-items">
        <div className="p-school-items-title">
          <div>全国の歯科衛生士学校 一覧</div>
          <div><span>{data.allSchool.totalCount}</span>件</div>
        </div>
        {schools.map((school) => (
          <SchoolTag.Card data={school.node} />
        ))}
        <Paging path={linkUrl} currentPage={currentPage} numPages={numPages} />
      </div>
      <SchoolAreaBlock countData={data.area.group} areaSortList={areaSortList} />
    </Layout>
  )
}

export const query = graphql`
  query($skip: Int!, $limit: Int!) {
    meta: allMeta(filter: {slug: {eq: "/school/"}}) {
      nodes {
        title
        description
        keywords
      }
    }
    allSchool(
      sort: { order: [ASC, DESC], fields: [order, updated] }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          slug
          school_id
          school_type
          school_name
          corporate_name
          area
          prefecture_name
          municipalities
          order
          night_capacity
          list_heading
        }
      }
      totalCount
    }
    area: allSchool(filter: { prefecture: { ne: "" } }) {
      group(field: area) {
        fieldValue
        totalCount
      }
    }
  }
`